import {IValue} from '../IValue';

export interface IMetadataPage {
  metadata: IMIMetadata[];
  activeMetadata: IMIMetadata;
  needRefreshMetadata: boolean;
  metadataUploadValidation?: IMMetadataUploadValidation;
}

export interface IMMetadataUploadValidation {
  validationStatus: IMMetadataValidationStatus;
  message: string;
  numberOfRows: number;
  numberOfProcessedRows: number;
  savingStatus: MassiveImportSavingStatus;
}
export enum MassiveImportSavingStatus{
    NotSaved = 0,
    Saving = 1,
    Saved = 2,
    Error = 3
}

export enum IMMetadataValidationStatus {
  Pending = 0,
  Completed = 1,
  Error = 2,
  TimeOut = 3
}

export interface IMIMetadata {
  id: string;
  documentTitle: IMetadataFieldDetail;
  professionalArea: IMetadataFieldDetailWithId;
  documentType: IMetadataFieldDetail;
  documentSubType: IMetadataFieldDetail;
  country: IMetadataFieldDetail;
  documentDate: IMetadataFieldDetail;
  securityLevel: IMetadataFieldDetail;
  source: IMetadataFieldDetail;
  authors: IMetadataFieldDetail;
  whitelist: IMetadataFieldDetail;
  description: IMetadataFieldDetail;
  remarks: IMetadataFieldDetail;
  tags: IMetadataFieldDetail;
  fileName: IMetadataFieldDetail;
  attachments: IMetadataFieldDetail;
  bo1_type: IMetadataFieldDetail;
  bo1_value: IMetadataFieldDetail;
  bo2_type: IMetadataFieldDetail;
  bo2_value: IMetadataFieldDetail;
  bo3_type: IMetadataFieldDetail;
  bo3_value: IMetadataFieldDetail;
  seismicAcquisitions: IMetadataFieldDetail;
  seismicSurveys: IMetadataFieldDetail;
  bo_validation: IMetadataFieldDetail;
  metadata_projectCode: IMetadataFieldDetail;
  metadata_documentCode: IMetadataFieldDetail;
  metadata_projectName: IMetadataFieldDetail;
  metadata_projectPhase: IMetadataFieldDetail;
  metadata_reasonForIssue: IMetadataFieldDetail;
  metadata_contractName: IMetadataFieldDetail;
  metadata_contractNumber: IMetadataFieldDetail;
  metadata_contractorName: IMetadataFieldDetail;
  metadata_issueDate: IMetadataFieldDetail;
  metadata_documentId: IMetadataFieldDetail;
  metadata_transmittalNode: IMetadataFieldDetail;
  metadata_originator: IMetadataFieldDetail;
  metadata_receiver: IMetadataFieldDetail;
  metadata_revisionNumber: IMetadataFieldDetail;
  metadata_validation: IMetadataFieldDetail;
  unzip: IMetadataFieldDetail;
  sheetRow: IMetadataFieldDetail;
  status: IMIMetadataStatus;
}

export enum IMIMetadataStatus {
  SUCCESS,
  WARNING,
  FAIL,
}

export interface IMetadataFieldDetail {
  value: string;
  errorMessages: string[];
  warningMessages?: string[];
}

export interface IMetadataFieldDetailWithId extends IMetadataFieldDetail {
  id: string;
}

export class MIMetadata implements IMIMetadata {
  id: string = '';
  documentTitle: MetadataFieldDetail = new MetadataFieldDetail();
  professionalArea: IMetadataFieldDetailWithId = new MetadataFieldDetailWithId();
  documentType: MetadataFieldDetail = new MetadataFieldDetail();
  documentSubType: MetadataFieldDetail = new MetadataFieldDetail();
  country: MetadataFieldDetail = new MetadataFieldDetail();
  documentDate: MetadataFieldDetail = new MetadataFieldDetail();
  securityLevel: MetadataFieldDetail = new MetadataFieldDetail();
  source: MetadataFieldDetail = new MetadataFieldDetail();
  authors: MetadataFieldDetail = new MetadataFieldDetail();
  whitelist: MetadataFieldDetail = new MetadataFieldDetail();
  description: MetadataFieldDetail = new MetadataFieldDetail();
  remarks: MetadataFieldDetail = new MetadataFieldDetail();
  tags: MetadataFieldDetail = new MetadataFieldDetail();
  fileName: MetadataFieldDetail = new MetadataFieldDetail();
  attachments: MetadataFieldDetail = new MetadataFieldDetail();
  bo1_type: IMetadataFieldDetail = new MetadataFieldDetail();
  bo1_value: IMetadataFieldDetail = new MetadataFieldDetail();
  bo2_type: IMetadataFieldDetail = new MetadataFieldDetail();
  bo2_value: IMetadataFieldDetail = new MetadataFieldDetail();
  bo3_type: IMetadataFieldDetail = new MetadataFieldDetail();
  bo3_value: IMetadataFieldDetail = new MetadataFieldDetail();
  seismicAcquisitions: IMetadataFieldDetail = new MetadataFieldDetail();
  seismicSurveys: IMetadataFieldDetail = new MetadataFieldDetail();
  bo_validation: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_projectCode: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_documentCode: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_projectName: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_projectPhase: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_reasonForIssue: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_contractName: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_contractNumber: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_contractorName: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_issueDate: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_documentId: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_transmittalNode: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_originator: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_receiver: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_revisionNumber: IMetadataFieldDetail = new MetadataFieldDetail();
  metadata_validation: IMetadataFieldDetail = new MetadataFieldDetail();
  unzip: MetadataFieldDetail = new MetadataFieldDetail();
  sheetRow: MetadataFieldDetail = new MetadataFieldDetail();
  status = IMIMetadataStatus.FAIL;
}

class MetadataFieldDetail implements IMetadataFieldDetail {
  value: string = '';
  errorMessages: string[] = [];
  warningMessages: string[] = [];
}

class MetadataFieldDetailWithId implements IMetadataFieldDetailWithId {
  value: string = '';
  errorMessages: string[] = [];
  warningMessages: string[] = [];
  id: string = '';
}

export const MIMetadataStatusVisualization = [
  {key: IMIMetadataStatus.SUCCESS.toString(), text: 'Valid'},
  {key: IMIMetadataStatus.FAIL.toString(), text: 'Not Valid'},
  {key: IMIMetadataStatus.WARNING.toString(), text: 'Warning'},
];

/*********************************************** API Responses Data model *********************************************/

export interface IMIMetadataResponse {
  id: string;
  documentTitle: string;
  professionalArea: IValue;
  documentType: IValue;
  documentSubType: IValue;
  country: IValue;
  documentDate: string;
  securityLevel: string;
  source: string;
  authors: string[];
  whitelist: string[];
  description: string;
  remarks: string;
  tags: string[];
  fileName: string;
  attachments: string[];
  bOs?: IMIMetadataBos;
  metadata?: IMetadataMetadata;
  unzip: boolean;
  sheetRow: number;
  messages: IMIMetadataMessage;
  seismicAcquisitions: string[];
  seismicSurveys: string[];
}

export interface IMIMetadataBos {
  [key: string]: string[];
}

export interface IMetadataMetadata {
  projectCode?: IValue;
  projectName?: IValue;
  projectPhase?: IValue;
  reasonforIssue?: IValue;
  contractName?: IValue;
  contractNumber?: IValue;
  contractorName?: IValue;
  issueDate?: IValue;
  transmittalNode?: IValue;
  originator?: IValue;
  receiver?: IValue;
  revisionNumber?: IValue;
  documentId?: IValue;
  developmentDocumentCode?: IValue;
}

interface IMIMetadataMessage {
  isSuccessful: boolean;
  metadata: IMIMetadataMessageStatus;
  bo: IMIMetadataMessageStatus;
  permissions: IMIMetadataMessageStatus;
  files: IMIMetadataMessageStatus;
  additionalInfos: IMIMetadataMessageStatus;
}

interface IMIMetadataMessageStatus {
  isSuccessful: boolean;
  messages: IMIMetadataMessageStatusMessage[];
}

export interface IMIMetadataMessageStatusMessage {
  code: number;
  message: string;
  impactedColumns: string[];
}
